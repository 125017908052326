var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.modalId,"size":"lg","hide-footer":""},on:{"shown":_vm.load},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" パスワードの再設定 ")]},proxy:true}])},[(!_vm.isSelf)?_c('div',[_c('p',[_vm._v("\""+_vm._s(_vm.user.name)+"\"ユーザーのパスワードを再設定")])]):_vm._e(),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.accept)}}},[_c('div',{staticClass:"p-3 password-bg"},[(!_vm.isSelf)?_c('div',{staticClass:"text-center"},[_c('b-form-checkbox',{model:{value:(_vm.isGenerate),callback:function ($$v) {_vm.isGenerate=$$v},expression:"isGenerate"}},[_vm._v("パスワードの自動生成")]),(_vm.isGenerate)?_c('p',{staticClass:"mt-2"},[_vm._v(" 自動生成したパスワードを担当者にメールで\""+_vm._s(_vm.user.email)+"\""),_c('br'),_vm._v("メールアドレスに送信します。 ")]):_vm._e()],1):_vm._e(),(!_vm.isGenerate)?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-offset-3"},[(_vm.isSelf)?_c('validation-provider',{attrs:{"name":"現在のパスワード","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"現在のパスワード"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":_vm.currentVisible ? 'text' : 'password'},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}}),_c('b-input-group-addon',[_c('j-button',{staticClass:"no-min btn-light-link",attrs:{"buttonType":"button"},on:{"click":function($event){_vm.currentVisible = !_vm.currentVisible}}},[_c('font-awesome-icon',{attrs:{"icon":"eye"}})],1)],1)],1)],1),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('validation-provider',{staticClass:"mt-2",attrs:{"mode":"eager","name":"新しいパスワード","rules":{
                  required: true,
                  passwordStrength: _vm.$store.state.user.companyCode,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"新しいパスワード"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":_vm.passwordVisible ? 'text' : 'password'},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-addon',[_c('j-button',{staticClass:"no-min btn-light-link",attrs:{"buttonType":"button"},on:{"click":function($event){_vm.passwordVisible = !_vm.passwordVisible}}},[_c('font-awesome-icon',{attrs:{"icon":"eye"}})],1)],1)],1),_c('pre',{staticClass:"invalid-feedback d-inline override-font"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"mt-2",attrs:{"mode":"eager","name":"新しいパスワード（再入力）","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"新しいパスワード（再入力）"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":_vm.passwordAgainVisible ? 'text' : 'password'},model:{value:(_vm.passwordAgain),callback:function ($$v) {_vm.passwordAgain=$$v},expression:"passwordAgain"}}),_c('b-input-group-addon',[_c('j-button',{staticClass:"no-min btn-light-link",attrs:{"buttonType":"button"},on:{"click":function($event){_vm.passwordAgainVisible = !_vm.passwordAgainVisible}}},[_c('font-awesome-icon',{attrs:{"icon":"eye"}})],1)],1)],1)],1),_c('pre',{staticClass:"invalid-feedback d-inline override-font"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),(!_vm.isSelf)?_c('p',{staticClass:"mt-2"},[_vm._v(" 更新されたパスワードを担当者にメールで\""+_vm._s(_vm.user.email)+"\""),_c('br'),_vm._v("メールアドレスに送信します。 ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"controls text-center mt-2"},[_c('j-button',{staticClass:"mt-3",attrs:{"variant":"primary","buttonType":"submit"}},[_vm._v(" "+_vm._s(_vm.isSelf ? "保存" : "送信")+" ")]),_c('j-button',{staticClass:"mt-3 ml-4",attrs:{"variant":"light","buttonType":"button"},on:{"click":function($event){return _vm.reject()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times']}}),_vm._v(" キャンセル ")],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }