<template>
  <b-modal :id="modalId" @shown="load" size="lg" hide-footer>
    <template v-slot:modal-title> パスワードの再設定 </template>
    <div v-if="!isSelf">
      <p>"{{ user.name }}"ユーザーのパスワードを再設定</p>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(accept)">
        <div class="p-3 password-bg">
          <div class="text-center" v-if="!isSelf">
            <b-form-checkbox v-model="isGenerate"
              >パスワードの自動生成</b-form-checkbox
            >
            <p v-if="isGenerate" class="mt-2">
              自動生成したパスワードを担当者にメールで"{{
                user.email
              }}"<br />メールアドレスに送信します。
            </p>
          </div>
          <div class="mt-4" v-if="!isGenerate">
            <div class="row">
              <div class="col-6 col-offset-3">
                <validation-provider
                  name="現在のパスワード"
                  :rules="{ required: true }"
                  v-if="isSelf"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    class="biz-input-group"
                    label="現在のパスワード"
                  >
                    <b-input-group>
                      <b-form-input
                        :type="currentVisible ? 'text' : 'password'"
                        v-model="current"
                      />
                      <b-input-group-addon>
                        <j-button
                          buttonType="button"
                          @click="currentVisible = !currentVisible"
                          class="no-min btn-light-link"
                        >
                          <font-awesome-icon icon="eye" />
                        </j-button>
                      </b-input-group-addon>
                    </b-input-group>
                  </b-form-group>
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </validation-provider>
                <validation-provider
                  mode="eager"
                  class="mt-2"
                  name="新しいパスワード"
                  :rules="{
                    required: true,
                    passwordStrength: $store.state.user.companyCode,
                  }"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    class="biz-input-group"
                    label="新しいパスワード"
                  >
                    <b-input-group>
                      <b-form-input
                        :type="passwordVisible ? 'text' : 'password'"
                        v-model="password"
                      />
                      <b-input-group-addon>
                        <j-button
                          buttonType="button"
                          @click="passwordVisible = !passwordVisible"
                          class="no-min btn-light-link"
                        >
                          <font-awesome-icon icon="eye" />
                        </j-button>
                      </b-input-group-addon>
                    </b-input-group>
                    <pre class="invalid-feedback d-inline override-font">{{
                      errors[0]
                    }}</pre>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  mode="eager"
                  class="mt-2"
                  name="新しいパスワード（再入力）"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    class="biz-input-group"
                    label="新しいパスワード（再入力）"
                  >
                    <b-input-group>
                      <b-form-input
                        :type="passwordAgainVisible ? 'text' : 'password'"
                        v-model="passwordAgain"
                      />
                      <b-input-group-addon>
                        <j-button
                          buttonType="button"
                          @click="passwordAgainVisible = !passwordAgainVisible"
                          class="no-min btn-light-link"
                        >
                          <font-awesome-icon icon="eye" />
                        </j-button>
                      </b-input-group-addon>
                    </b-input-group>
                  </b-form-group>
                  <pre class="invalid-feedback d-inline override-font">{{
                    errors[0]
                  }}</pre>
                </validation-provider>
              </div>
            </div>
            <p class="mt-2" v-if="!isSelf">
              更新されたパスワードを担当者にメールで"{{
                user.email
              }}"<br />メールアドレスに送信します。
            </p>
          </div>
        </div>
        <div class="controls text-center mt-2">
          <j-button class="mt-3" variant="primary" buttonType="submit">
            {{ isSelf ? "保存" : "送信" }}
          </j-button>
          <j-button
            class="mt-3 ml-4"
            variant="light"
            buttonType="button"
            @click="reject()"
          >
            <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
            キャンセル
          </j-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import UserInfo from "@/model/UserInfo";
import { setInteractionMode } from "vee-validate";

export default {
  props: ["modalId", "user", "isSelf"],
  data: function () {
    return {
      isGenerate: !this.isSelf,
      current: "",
      currentVisible: false,
      password: "",
      passwordVisible: false,
      passwordAgain: "",
      passwordAgainVisible: false,
    };
  },
  created: function () {
    setInteractionMode("passive");
  },
  methods: {
    accept() {
      if (this.password != this.passwordAgain) {
        this.$warningMessage("新しいパスワードと新しいパスワード（再入力）が一致しません。");
        return;
      }
      UserInfo.changePassword(this.$store.state.user.companyCode, {
        userCode: this.user.code,
        isGenerate: this.isGenerate,
        isSelf: this.isSelf,
        password: this.password,
        currentPassword: this.current,
      })
        .then(() => {
          setInteractionMode("eager");
          this.$bvModal.hide(this.modalId);
          if (this.isSelf) {
            this.$successMessage("パスワードが変更されました。");
          } else {
            this.$successMessage(
              "メールアドレス宛に新規パスワードをお送りしました。"
            );
          }
        })
        .catch((reason) => {
          const appCode = reason.response.data;
          switch (appCode) {
            case "OA_0001":
              this.$errorMessage(
                "過去に設定されたパスワードです。別のパスワードを設定してください。"
              );
              break;
            case "OA_0002":
              this.$errorMessage("現状のパスワードが正しくありません。");
              break;
            case "OA_0003":
              this.$errorMessage(
                "The password does not comply with password rules"
              );
              break;
            default:
              this.$errorMessage("Error occurred while changing password.");
              break;
          }
        });
    },
    reject() {
      this.$bvModal.hide(this.modalId);
    },
    load() {
      this.currentPassword = "";
      this.password = "";
      this.passwordAgain = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.password-bg {
  background: $biz-side-bar-color;
}
.btn-light-link {
  border: none;
}
</style>
