import Model from './_base';

export default class UserInfo extends Model {
    static entity = 'user-info';
    
    constructor() {
      super(UserInfo.entity);
    }

    static checkUser(companyCode, email, userCode){
      return Model.axios.get(this.url('/' + companyCode + '/check-user?email=' + email + ( userCode? '&userCode=' + userCode:"" )));
    }

    static getSelf(companyCode){
      return Model.axios.get(this.url('/' + companyCode + '/self'));
    }

    static getUserSelect(companyCode){
      return Model.axios.get(this.url('/' + companyCode));
    }

    static getMain(companyCode){
      return Model.axios.get(this.url('/' + companyCode + '/main'));
    }

    static getSubs(companyCode){
      return Model.axios.get(this.url('/' + companyCode + '/subs'));
    }

    static getSigner(companyCode){
      return Model.axios.get(this.url('/' + companyCode + '/signers'));
    }

    static save(companyCode, form){
      return Model.axios.post(this.url('/' + companyCode), form);
    }

    static saveSelf(companyCode, form){
      return Model.axios.post(this.url('/' + companyCode + '/self'), form);
    }

    static updateMainUser(companyCode, form){
      return Model.axios.post(this.url('/' + companyCode + '/update-main-user'), form);
    }
    

    static saveSigner(companyCode, form){
      return Model.axios.post(this.url('/' + companyCode + '/signers'), form);
    }

    static deleteSub(companyCode, code){
      return Model.axios.delete(this.url('/' + companyCode + '/subs/' + code));
    }

    static changePassword(companyCode, data){
      return Model.axios.put(this.url('/' + companyCode + '/password-change'), data);
    }

    static requestSign(companyCode, data){
      return Model.axios.post(this.url('/' + companyCode + '/request-sign'), data);
    }

    static getMailSupplierSetting(companyCode,supplierCode){
      return Model.axios.get(this.url('/' + companyCode + '/mail-supplier-setting/get?supplierDisplayCode=' + supplierCode));
    }
    static saveMailSupplierSetting(companyCode,data){
      return Model.axios.post(this.url('/' + companyCode + '/mail-supplier-setting/save'), data)
    }

    static settingDutyUser(companyCode,data) {
      return Model.axios.post(this.url('/' + companyCode + '/setting-duty-user'), data);
    }

    static searchDuty(companyCode,email){
      return Model.axios.get(this.url('/' + companyCode + '/search-duty?email=' + email));
    }

    static settingDuty(companyCode,data) {
      return Model.axios.post(this.url('/' + companyCode + '/setting-duty'), data);
    }
}