<template>
  <div>
    <div class="mt-4 card biz-primary">
      <div class="card-header font-weight-bold">
        {{$t('USERS.HEADER_MAIN_USER')}}
        <div v-if="!previewMode" class="control">
          <router-link to="/supplier/manage/user-info/edit">{{$t('COMMON.EDIT')}}</router-link>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="biz-form-group">
              <label class="biz-form-label font-weight-bold">{{$t('USERS.SALES_MANAGER')}}</label>
              <div class="biz-form-body">
                <p class="biz-offset">{{mainUser.organizationName || $t('COMMON.NOT_REGISTERED')}}</p>
                <p class="">{{mainUser.name || $t('COMMON.NOT_REGISTERED')}}</p>
                <p class="">{{mainUser.companyMobilePhone || $t('COMMON.NOT_REGISTERED')}}</p>
                <p class="">{{mainUser.email || $t('COMMON.NOT_REGISTERED')}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="biz-form-group">
              <label class="biz-form-label font-weight-bold">{{$t('USERS.ROLES')}}</label>
              <div class="biz-form-body">
                <pre class="biz-offset override-font">{{
                  mainUser.roles ? mainUser.roles.map(r => r.roleName).join('\n') : $t('COMMON.NOT_REGISTERED')}}</pre>
                <p v-if="!previewMode"> 
                  <b-link v-on:click="openChangePasswordModal(mainUser)">
                    <font-awesome-icon icon="lock"/> {{$t('USERS.SET_PASSWORD')}}
                  </b-link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="biz-form-group">
              <label class="biz-form-label font-weight-bold">{{$t('USERS.SALES_MANAGER')}}<br/>{{$t('USERS.ORGANIZATION_ADDRESS')}}</label>
              <div class="biz-form-body">
                <p class="biz-offset">{{mainUser.orgPostalCode || $t('COMMON.NOT_REGISTERED')}}</p>
                <p class="">{{mainUser.orgAddress1 + mainUser.orgAddress2}}</p>
              </div>
            </div>
          </div>
          <div class="col">
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 card biz-primary">
      <div class="card-header font-weight-bold">
        {{$t('USERS.HEADER_OTHER_USERS')}}
        <div v-if="!previewMode" class="control">
          <router-link to="/supplier/manage/user-info/edit">{{$t('COMMON.EDIT')}}</router-link>
        </div>
      </div>
      <div class="card-body">    
        <b-alert :show="subs.length <= 0" variant="light">{{$t('USERS.WARNING_NO_OTHER_USER_REGISTERED_YET')}}</b-alert>
        <div class="row" v-for="(sub, index) in subs" v-bind:key="sub.id">
          <div class="col">
            <div class="biz-form-group">
              <label class="biz-form-label font-weight-bold">{{$t('USERS.OTHER_USER')}}{{index + 1}}</label>
              <div class="biz-form-body">
                <p class="biz-offset">{{sub.organizationName || $t('COMMON.NOT_REGISTERED')}}</p>
                <p class="">{{sub.name || $t('COMMON.NOT_REGISTERED')}}</p>
                <p class="">{{sub.companyMobilePhone || $t('COMMON.NOT_REGISTERED')}}</p>
                <p class="">{{sub.email || $t('COMMON.NOT_REGISTERED')}}</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="biz-form-group">
              <label class="biz-form-label font-weight-bold">{{$t('USERS.ROLES')}}</label>
              <div class="biz-form-body">
                <pre class="biz-offset override-font">{{sub.roles ? sub.roles.filter(r => r.checked).map(r => r.roleName).join('\n') : $t('COMMON.NOT_REGISTERED')}}</pre>
                <p v-if="!previewMode"> 
                  <b-link v-on:click="openChangePasswordModal(sub)">
                    <font-awesome-icon icon="lock"/> {{$t('USERS.SET_PASSWORD')}}
                  </b-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['mainUser', 'subs', 'previewMode'],
  methods:{
    openChangePasswordModal(user){
      this.$emit("passwordChange", user);
    }
  }
}
</script>

<style>
  
</style>