<template>
  <div>
    <ul class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('USERS.TITLE')}}</a></li>
    </ul>
    <div class="page">
      <UserInfoView :previewMode="false" :mainUser="mainUser" :subs="subs" @passwordChange="openChangePasswordModal"/>
      <div class="text-center mt-5">
        <j-button variant="light" to="/supplier/manage">
          <font-awesome-icon icon="arrow-left"></font-awesome-icon>
          {{$t('COMMON.GO_BACK')}}
        </j-button>
      </div>
    </div>
    <PasswordChange v-if="selectedUser" modal-id="password-change-modal" :user="selectedUser"></PasswordChange>
  </div>
</template>

<style scoped lang="scss">

</style>
<script>
  import UserInfo from '@/model/UserInfo';
  import PasswordChange from '@/components/user/PasswordChange.vue';
  import UserInfoView from '../../components/common/UserInfoView.vue';

  export default {
    components: { UserInfoView, PasswordChange },
    data: () => ({
      mainUser: {},
      subs: [],
      selectedUser: null
    }),
    mounted(){
      Promise.all([
        UserInfo.getMain(this.$store.state.user.companyCode),
        UserInfo.getSubs(this.$store.state.user.companyCode)
      ]).then((results) => {
        this.$data.mainUser = results[0].data;
        this.$data.subs = results[1].data;
      });
    },
    methods: {
      openChangePasswordModal(user){
        this.selectedUser = user;
        this.$bvModal.show('password-change-modal');
      }
    }
  }
</script>